<template>
  <div class="relative h-screen bg-black">
    <div class="bg-asgard-login bg-no-repeat w-full h-full bg-center flex justify-center items-center">
      <div class="flex justify-center items-center">
        <div class="border-2 border-primary-red rounded-xl flex justify-between items-center py-4 px-8 w-105 mt-8 bg-gray-50 bg-opacity-30">
          <img
            class="w-31 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105"
            :src="require('@/assets/images/styleGuides.png')"
            @click="selectPortal({ name: 'StyleGuide' })"
          >
          <img
            class="w-31 cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105"
            :src="require('@/assets/images/brandAssurance.png')"
            @click="selectPortal({ name: 'BrandAssurance' })"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
    name: 'PortalSelection',

    setup () {
        const router = useRouter();

        const selectPortal = (portalRoute) => {
            router.replace(portalRoute);
        };

        return {
            selectPortal
        };
    }
};
</script>
